<style lang="scss">
.exklusivplatzierung {
  .format-box {
    margin-bottom: 20px;
    .col-lg-5,
    .col-lg-3 {
      justify-content: center;
      align-self: center;
    }
    img {
      display: block;
      margin: 0 auto;
      max-height: 120px;
    }
    .tabelle-box {
      .headline {
        text-transform: none;
        strong {
          display: block;
          text-transform: uppercase;
        }
      }
      .head-row {
        font-size: 80%;
      }
    }
  }
  .image-box {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
</style>

<template>
  <div class="exklusivplatzierung content container">
    <h1>Exklusivplatzierungen</h1>
    <p class="intro-txt">Eine Exklusivplatzierung rückt Ihre Werbebotschaft in den Fokus und sichert Ihnen garantiert hohe Aufmerksamkeit. Denn jede Werbeform ist pro Tag nur einmal buchbar und durch die Platzierung im Umfeld von Serviceinhalten ist der Leser nicht nur länger auf der Seite, sondern auch öfter. Das schafft wertvolle Mehrfachkontakte.</p>
    <div class="intro-img">
      <img src="@/assets/img/img-content/intro-img/exlusiveplatzierung.jpg" alt="Exklusivplatzierung" class="img-fluid" />
    </div>

    <div class="whitespace effect7"></div>
    <h2>Ihre Werbemöglichkeiten</h2>
    <div class="row image-box">
      <div class="col-lg-4 justify-content-center align-self-center">
        <img src="@/assets/img/img-content/hauptblatt/exklusivplatzierung-v3.png" alt="Exklusivplatzierung" class="img-fluid " />
      </div>
      <div class="col-lg-4 justify-content-center align-self-center">
        <img src="@/assets/img/img-content/hauptblatt/exklusivplatzierung-v1.png" alt="Exklusivplatzierung" class="img-fluid " />
      </div>
      <div class="col-lg-4 justify-content-center align-self-center">
        <img src="@/assets/img/img-content/hauptblatt/exklusivplatzierung-v2.png" alt="Exklusivplatzierung" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Titelseite</strong>
            Fixformat: 47 x 45 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">4.653,00</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">3.336,75</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">2.040,75</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">4.923,00</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">3.483,00</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">2.130,75</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x45.svg" alt="47x45" class="img-fluid " />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6"></div>
      <div class="col-lg-6"></div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Titelseite</strong>
            Fixformat: 98 x 45 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">9.306,00</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">6.673,50</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">4.081,50</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">9.846,00</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">6.966,00</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">4.261,50</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x45.svg" alt="98x45" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Titelseite + Leute /U4 </strong>
            Fixformat: 414 x 45 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">30.709,80</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">22.022.55</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">13.468,95</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">32.491,80</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">22.987,80</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">14.062,95</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-414x45.svg" alt="414x45" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Leute / U4</strong>
            Fixformat: 98 x 45 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">3.722,40</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">2.669,40</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">1.632,60</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">3.938,40</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">2.786,40</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">1.704,60</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x45.svg" alt="98x45" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Leute / U4</strong>
            Fixformat: 149 x 45 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">5.583,60</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">4.004,10</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">2.448,90</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">5.907,60</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">4.179,60</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">2.556,90</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-149x45.svg" alt="149x45" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Leute / U4</strong>
            Fixformat: 200 x 45 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">7.444,80</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">5.338,80</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">3.265,20</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">7.876,80</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">5.572,80</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">3.409,20</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x45.svg" alt="200x45" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>U3</strong>
            Fixformat: 200 x 275 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">28.435,00</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">20.391,25</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">12.471,25</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">30.085,00</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">21.285,00</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">13.021,25</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x275" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Wetter</strong>
            Fixformat: 98 x 45 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">2.326,50</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">1.668,38</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">1.020,38</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">2.461,50</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">1.741,50</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">1.065,38</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x45.svg" alt="98x45" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Wetter</strong>
            Fixformat: 200 x 45 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">4.653,00</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">3.336,75</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">2.040,75</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">4.923,00</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">3.483,00</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">2.130,75</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x45.svg" alt="200x45" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>TV-Programm</strong>
            Fixformat: 149 x 55 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">4.435,86</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">3.181,04</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">1.945,52</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">4.693,26</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">3.320,46</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">2.031,32</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-149x55.svg" alt="149x55" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Rätselseite/Liebe ist… </strong>
            Fixformat: 47 x 45 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">1.163,25</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">834,19</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">510,19</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">1.230,75</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">870,75</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">532,69</div>
          </div>
          <div class="info-txt-tabelle">Sonntags keine Erscheinung möglich</div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x45.svg" alt="47x45" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Rätselseite/Liebe ist… </strong>
            Fixformat: 98 x 45 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">2.326,50</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">1.668,38</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">1.020,38</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">2.461,50</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">1.741,50</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">1.065,38</div>
          </div>
          <div class="info-txt-tabelle">Sonntags keine Erscheinung möglich</div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x45.svg" alt="98x45" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Kreuzworträtsel </strong>
            Fixformat: 75 x 72 mm
          </div>
          <div class="row head-row">
            <div class="col-lg-12 text-align-right text-center-small">Kombination</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left">MO - FR</div>
            <div class="col-lg-6 col-6 text-align-right">3.871,30</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left">SA - SO</div>
            <div class="col-lg-6 col-6 text-align-right">4.095,94</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-75x72.svg" alt="75x72" class="img-fluid " />
      </div>
    </div>
    <div class="info-txt-preis">Alle Preise verstehen sich in Euro, zuzüglich 5 % Werbeabgabe und 20 % Umsatzsteuer.</div>
    <div class="download-pdf">
      <div class="line"><span>Download</span></div>
      <a href="../../pdf/hauptblatt/Exklusiv-Platzierung-07-2021.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarif Exklusivplatzierung
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>445 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
    <Modal></Modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Modal from '@/components/util/Modal.vue';

export default defineComponent({
  name: 'Exklusivplatzierung',
  components: {
    Modal,
  },
});
</script>
